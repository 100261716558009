import { KontentMedia } from '../../../../types/KontentProps';

export function getFluidImageData(image: KontentMedia) {
    const imageData = image?.value[0]?.elements.file?.value[0];
    return {
        source: imageData?.fluid,
        alt: imageData?.description || imageData?.name,
    };
}

export function getFixedImageData(image: KontentMedia) {
    const imageData = image?.value[0]?.elements.file?.value[0];
    return {
        source: imageData?.fixed,
        alt: imageData?.description || imageData?.name,
    };
}
