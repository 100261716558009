import React, { useState, useEffect } from 'react';
//@ts-ignore
import * as styles from './blog-scroll.module.css';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ArrowLgButton from '../Global/ArrowLgButton';
import {
    getMediaData,
    getMultiChoice,
    getTimeToRead,
} from '../Global/DataUtils';
import { BlogPagePreview } from '../Global/DataUtils/blogQueries';
import { BlogScrollProps } from './index';
import { getFluidImageData } from '../Global/DataUtils/gatsbyImageUtils';
import GatsbyImage from 'gatsby-image';
import {defaultDomainCountryCodes}  from '../../global/countries'

interface Props extends BlogScrollProps {
    content: BlogPagePreview[];
    preferredLanguage?: string;
}

const BlogScroll: React.FC<Props> = ({
    anchor_name,
    cta_label,
    headline,
    relative_large_display,
    relative_small_display,
    ...props
}) => {
    if (props.content.length === 0) return null;

    const breakpoints = useBreakpoint();
    const contentDisplayType = getMultiChoice(props.content_display_type);
    const langPrefix = defaultDomainCountryCodes.indexOf(props.preferredLanguage?.toLowerCase()) > -1 ? '' : `/${props.preferredLanguage?.toLowerCase()}`
    
    const sliceContent = () => {
        const relativeLargeDisplay = relative_large_display?.value || 6;
        const relativeSmallDisplay = relative_small_display?.value || 4;

        if (breakpoints.l) {
            return props.content.slice(0, relativeLargeDisplay);
        }

        return props.content.slice(0, relativeSmallDisplay);
    };

    let content =
        contentDisplayType === 'relative' ? sliceContent() : props.content;

    const currentDisplayNumber = breakpoints.l ? 3 : 1;
    const [visibleItems, setVisibleItems] = useState(
        content?.slice(0, currentDisplayNumber)
    );
    const [currentItem, setCurrentItem] = useState(0);
    const layoutDisplayType = getMultiChoice(props.layout_display_type);

    useEffect(() => {
        setVisibleItems(content?.slice(0, currentDisplayNumber));
    }, [currentDisplayNumber]);

    useEffect(() => {
        content =
            contentDisplayType === 'relative' ? sliceContent() : props.content;
    }, [content]);

    const handleClickPrev = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const newItem = currentItem - 1;
        setVisibleItems(
            content?.slice(newItem, newItem + currentDisplayNumber)
        );
        setCurrentItem(newItem);
    };

    const handleClickNext = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const newItem = currentItem + 1;
        setVisibleItems(
            content?.slice(newItem, newItem + currentDisplayNumber)
        );
        setCurrentItem(newItem);
    };

    const renderItem = (scrollItem: BlogPagePreview, idx: number) => {
        if (breakpoints.l || idx === 0 || layoutDisplayType === 'stacked') {
            const blogPageData = scrollItem?.elements;
            const blogData = blogPageData?.other_metadata?.value[0];

            if (!blogData) {
                return null;
            }

            const postImage = getFluidImageData(
                blogData.elements?.blog_scroll_image
            );

            const minutesToRead = getTimeToRead(scrollItem);

            return (
                <div key={idx} className={styles.item}>
                    {minutesToRead > 0 && (
                        <p className={styles.readTime}>
                            {minutesToRead} min read
                        </p>
                    )}
                    <h3>{blogData?.elements?.title?.value}</h3>
                    {postImage?.source && (
                        <GatsbyImage
                            fluid={postImage?.source}
                            alt={postImage?.alt}
                            fadeIn={false}
                        />
                    )}
                    <p className={styles.description}>
                        {blogData?.elements?.short_description?.value}
                    </p>
                    <p>
                        <a
                            href={`${langPrefix}${blogPageData.slug.url}`}
                            className={styles.cta}
                        >
                            {cta_label?.value || 'Read More'}
                        </a>
                    </p>
                </div>
            );
        }
    };

    return (
        <section id={anchor_name?.value} className={styles.wrapper}>
            {headline && <h2>{headline?.value}</h2>}

            <div
                className={
                    layoutDisplayType === 'carousel'
                        ? styles.carousel
                        : styles.stacked
                }
            >
                {layoutDisplayType === 'carousel' && currentItem > 0 ? (
                    <ArrowLgButton
                        direction="left"
                        strokeColor="#f06623"
                        handleClick={handleClickPrev}
                        className={styles.prev}
                    />
                ) : null}
                <div className={styles.items}>
                    {layoutDisplayType === 'carousel'
                        ? visibleItems?.map((item, idx) =>
                              renderItem(item, idx)
                          )
                        : content?.map((item, idx) => renderItem(item, idx))}
                </div>
                {layoutDisplayType === 'carousel' &&
                currentItem < content?.length - 1 ? (
                    <ArrowLgButton
                        direction="right"
                        strokeColor="#f06623"
                        handleClick={handleClickNext}
                        className={styles.next}
                    />
                ) : null}
            </div>
        </section>
    );
};

export default BlogScroll;
