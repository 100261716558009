import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import BlogScroll from './BlogScroll';
//@ts-ignore
import { useBlogs, BlogPagePreview } from '../Global/DataUtils/blogQueries';
import { getMultiChoice } from '../Global/DataUtils';
import {
    KontentNumberValue,
    KontentRadioMultiChoice,
    KontentStringValue,
} from '../../../types/KontentProps';

type contentDisplayType = 'explicit' | 'relative';
type layoutDisplayType = 'carousel' | 'stacked';
type sortingOrder = 'most_recent' | 'most_relevant';
type KontentCodename = { codename: string };

export interface BlogScrollProps {
    anchor_name?: KontentStringValue;
    content_display_type: KontentRadioMultiChoice<contentDisplayType>;
    cta_label?: KontentStringValue;
    explicit_content_selection: { value: BlogPagePreview[] };
    featured_blog: { value: BlogPagePreview[] };
    headline?: KontentStringValue;
    layout_display_type?: KontentRadioMultiChoice<layoutDisplayType>;
    relative_large_display?: KontentNumberValue;
    relative_small_display?: KontentNumberValue;
    blog_topic_primary?: KontentRadioMultiChoice<string>;
    blog_topic_secondary?: KontentRadioMultiChoice<string>;
    relative_sorting_order?: KontentRadioMultiChoice<sortingOrder>;
}

const BlogScrollContainer: React.FC<BlogScrollProps> = (props) => {
    const pageMetadataContext = useContext(PageMetadataContext);

    const contentDisplayType = getMultiChoice(props.content_display_type);
    const primaryBlogTopic = getMultiChoice(props.blog_topic_primary);
    const secondaryBlogTopics: { [key: string]: boolean } = {};

    props.blog_topic_secondary?.value.map((topic: KontentCodename) => {
        let topicName = topic.codename;
        secondaryBlogTopics[topicName] = true;
    });
    const relativeSortingOrder = getMultiChoice(props.relative_sorting_order);

    const relativeLargeDisplay = props.relative_large_display?.value || 6;
    const relativeSmallDisplay = props.relative_small_display?.value || 4;

    let relativeDisplayNumber = Math.max(
        relativeLargeDisplay,
        relativeSmallDisplay
    );

    const selectBlogs = (): BlogPagePreview[] => {
        function blogFilter(callback: Function): BlogPagePreview[] {
            const allBlogPages = useBlogs(
                pageMetadataContext.preferredLanguage
            );

            if (
                !relativeDisplayNumber ||
                relativeDisplayNumber > allBlogPages.length
            ) {
                relativeDisplayNumber = allBlogPages.length;
            }

            let selectedBlogs = [] as BlogPagePreview[];

            let count = 0;
            let i = 0;

            while (count <= relativeDisplayNumber && i < allBlogPages.length) {
                const isNotSelf =
                    pageMetadataContext?.slug !=
                    allBlogPages[i].elements?.slug?.value;

                const isNotFeatured =
                    !props.featured_blog?.value[0] ||
                    props.featured_blog?.value[0]?.elements?.slug?.value !=
                        allBlogPages[i].elements?.slug?.value;

                const hasBlogScrollImage =
                    !!allBlogPages[i].elements?.other_metadata?.value[0]
                        ?.elements?.blog_scroll_image?.value[0];

                const isPreferredLanguage =
                    allBlogPages[i].preferred_language ===
                    pageMetadataContext.preferredLanguage;

                const isMappedToWebSpotlight =
                    allBlogPages[i].elements?.slug?.web_spotlight;

                if (
                    callback(allBlogPages[i]) &&
                    isNotSelf &&
                    isNotFeatured &&
                    hasBlogScrollImage &&
                    isPreferredLanguage &&
                    isMappedToWebSpotlight
                ) {
                    selectedBlogs.push(allBlogPages[i]);
                    count++;
                }

                i++;
            }

            return selectedBlogs;
        }

        function selectRecentBlogs() {
            return blogFilter((blogPage: BlogPagePreview) => {
                const blogPageTopics = blogPage.elements.blog_topic.value;
                if (!blogPageTopics || blogPageTopics.length === 0) {
                    return false;
                }
                for (let topic of blogPageTopics) {
                    if (
                        !primaryBlogTopic ||
                        topic.codename === primaryBlogTopic ||
                        secondaryBlogTopics[topic.codename]
                    ) {
                        return true;
                    }
                }
                return false;
            });
        }

        function selectRelevantBlogs() {
            let lessRelevantBlogs = [] as BlogPagePreview[];

            let mostRelevantBlogs = blogFilter((blogPage: BlogPagePreview) => {
                const blogPageTopics = blogPage.elements.blog_topic.value;

                if (!blogPageTopics || blogPageTopics.length === 0) {
                    return false;
                }
                for (let topic of blogPageTopics) {
                    if (
                        !primaryBlogTopic ||
                        topic.codename === primaryBlogTopic
                    ) {
                        return true;
                    }

                    if (secondaryBlogTopics[topic.codename]) {
                        lessRelevantBlogs.push(blogPage);
                    }
                }
                return false;
            });

            const gapToRelativeDisplayNumber =
                relativeDisplayNumber - mostRelevantBlogs.length;

            if (gapToRelativeDisplayNumber === 0) {
                return mostRelevantBlogs;
            } else {
                return [
                    ...mostRelevantBlogs,
                    ...lessRelevantBlogs.slice(0, gapToRelativeDisplayNumber),
                ];
            }
        }

        const selectedBlogs =
            relativeSortingOrder === 'most_recent'
                ? selectRecentBlogs()
                : selectRelevantBlogs();

        return selectedBlogs;
    };

    const getContent = () => {
        const featuredItem =
            props.featured_blog?.value[0]?.elements?.other_metadata?.value[0]
                ?.elements?.blog_scroll_image?.value.length > 0
                ? props.featured_blog.value[0]
                : undefined;

        let selectedContent: BlogPagePreview[] = [];

        switch (contentDisplayType) {
            case 'explicit':
                selectedContent = props.explicit_content_selection.value.filter(
                    (selectedContentItem) =>
                        selectedContentItem.elements?.other_metadata?.value[0]
                            ?.elements?.blog_scroll_image?.value.length > 0
                );
                break;
            case 'relative':
                selectedContent = selectBlogs();
                break;
            default:
                break;
        }

        if (featuredItem) {
            return [featuredItem, ...selectedContent];
        }

        return selectedContent;
    };

    const content = getContent();
    return (
        <BlogScroll
            {...props}
            content={content}
            preferredLanguage={pageMetadataContext.preferredLanguage}
        />
    );
};

export default BlogScrollContainer;

export const fragmentBlogScroll = graphql`
    fragment BlogScroll on kontent_item_component___blog_scroll {
        elements {
            anchor_name {
                value
            }
            blog_topic_primary {
                value {
                    codename
                }
            }
            blog_topic_secondary {
                value {
                    codename
                }
            }
            content_display_type {
                value {
                    codename
                }
            }
            cta_label {
                value
            }
            headline {
                value
            }
            layout_display_type {
                value {
                    codename
                }
            }
            relative_large_display {
                value
            }
            relative_small_display {
                value
            }
            relative_sorting_order {
                value {
                    codename
                }
            }
            explicit_content_selection {
                value {
                    ...blogPagePreview
                }
            }
            featured_blog {
                value {
                    ...blogPagePreview
                }
            }
        }
    }
`;
