// extracted by mini-css-extract-plugin
export var carousel = "blog-scroll-module--carousel--b284e";
export var cta = "blog-scroll-module--cta--9715a";
export var description = "blog-scroll-module--description--41289";
export var item = "blog-scroll-module--item--20594";
export var items = "blog-scroll-module--items--c356c";
export var next = "blog-scroll-module--next--5c7d4";
export var prev = "blog-scroll-module--prev--5008d";
export var readTime = "blog-scroll-module--readTime--16d3e";
export var stacked = "blog-scroll-module--stacked--0179c";
export var wrapper = "blog-scroll-module--wrapper--51fec";